import React, { FC } from 'react';
import classNames from 'classnames';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import './IntroProducts.scss';

type TIntroProductsComponentProps = {
  products: HomepageTypes.TProductProps[];
};

const baseClass = 'dt-intro__products-box';

const IntroProducts: FC<TIntroProductsComponentProps> = ({ products }) => {
  const linkClass = classNames(baseClass, {
    [`${baseClass}--single-element`]: products.length === 1,
  });

  return (
    <>
      {products.map(({ imageAlt, title, subTitle, link, image }) => (
        <a href={extractUrlFromMultiPicker(link)} className={linkClass} key={title + subTitle}>
          <div className={`${baseClass}-holder`}>
            <span className={`${baseClass}-label`}>{subTitle}</span>
            <strong className={`${baseClass}-title`}>{title}</strong>
          </div>
          <UmbracoImage className={`${baseClass}-img`} image={image} alt={imageAlt || ''} />
        </a>
      ))}
    </>
  );
};

export default IntroProducts;
