import React, { FC } from 'react';
import classNames from 'classnames';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import extractUrlFromMultiPicker from 'utils/urlMultiPicker';

import Intro from 'components/Intro/Intro';
import IntroProducts from 'components/Intro/IntroProducts';
import UmbracoImage from 'components/common/Image/UmbracoImage';
import Expertise from 'components/Expertise';
import NewQuiz from 'components/NewQuiz';
import PromoPanel from 'components/PromoPanel';
import PopularProducts from 'components/PopularProducts';
import MediaInfoBox from 'components/common/MediaInfoBox/MediaInfoBox';
import Media from 'components/common/MediaInfoBox/Media';
import Info from 'components/common/MediaInfoBox/Info';
import { TBodyRendererComponentProps } from './models';

import 'components/Intro/Intro.scss';
import './BodyRendererOverride.scss';

const BodyRenderer: FC<TBodyRendererComponentProps> = ({
  block,
  salsifyLang,
  relatedArticles,
  relatedProducts,
  promoProduct,
  introProducts,
}) => {
  switch (block.blockType) {
    case 'Top Banner Video': {
      const isSingleProduct = introProducts?.products?.length === 1;
      const productHolderClass = classNames('dt-intro__products-holder', {
        'dt-intro__products-holder--single-element': isSingleProduct,
      });

      return (
        <section className="dt-intro dt-container-wrapper" aria-label={block.sectionLandmark}>
          <MediaInfoBox
            sectionLandmark={block.sectionLandmark}
            isMask
            customImageToggle={block.panelImage}
          >
            <Media video={block.panelVideo}>
              <UmbracoImage image={block.panelImage} alt={block.imageAlt} />
            </Media>
            <Info postItNoteImage={block.postItNoteImage} postItNoteAlt={block.postItNoteAlt}>
              <h1
                className="dt-media-info-box__info-title"
                dangerouslySetInnerHTML={{ __html: block.titleText }}
              />
              <div className="dt-media-info-box__info-btn">
                <Button
                  href={extractUrlFromMultiPicker(block.buttonURL)}
                  size="lg"
                  aria-label={block.ariaLabel}
                >
                  {block.buttonText}
                </Button>
              </div>
            </Info>
          </MediaInfoBox>
          {introProducts?.products ? (
            <Container className="dt-intro__products" fluid>
              <div className={productHolderClass}>
                <IntroProducts products={introProducts.products} />
              </div>
            </Container>
          ) : null}
        </section>
      );
    }
    case 'Top Banner': {
      return (
        <Intro
          regularTitle={block.regularTitle}
          boldTitle={block.boldTitle}
          descriptionText={block.descriptionText}
          buttonText={block.buttonText}
          image={block.image}
          imageAlt={block.imageAlt}
          buttonURL={block.buttonURL}
          sectionLandmark={block.sectionLandmark}
          introProducts={introProducts}
        />
      );
    }
    case 'Articles carousel': {
      return (
        <Expertise
          title={{ boldText: block.boldTitle, regularText: block.regularTitle }}
          articles={relatedArticles}
          sectionLandmark={block.sectionLandmark}
        />
      );
    }
    case 'Assistant': {
      return (
        <NewQuiz
          xOrigin={block.xOrigin}
          salsifyLang={salsifyLang}
          questions={block.questions}
          buttons={{
            next: block.nextButtonText,
            back: block.prevButtonText,
            repeat: block.againButtonText,
          }}
          title={{
            regularText: block.titleRegularText,
            boldText: block.titleBoldText,
          }}
          searchResultsLabel={block.searchResultsPage}
          loadingLabel={block.resultsLoadingLabel ?? 'Sending request for finding products....'}
          noResultsLabel={block.noResultsFoundLabel ?? 'No results found'}
        />
      );
    }
    case 'Promo Panel': {
      return (
        <PromoPanel
          promoProduct={{
            productImage: promoProduct?.localHeroImage,
            productName: promoProduct?.name,
          }}
          buttonText={block.buttonText}
          buttonURL={block.buttonURL}
          ariaLabel={block.ariaLabel}
          descriptionText={block.descriptionText}
          titleText={block.titleText}
          imageAlt={block.imageAlt}
          boldTitle={block.boldTitle}
          labelText={block.labelText}
          panelType={block.panelType}
          panelImage={block.panelImage}
          panelVideo={block.panelVideo}
          sectionLandmark={block.sectionLandmark}
          disclaimer={block.disclaimer}
          productImage={block.productImage}
          customImageToggle={block.customImageToggle}
        />
      );
    }
    case 'Products carousel': {
      return (
        <PopularProducts
          products={relatedProducts}
          boldTitle={block.boldTitle}
          regularTitle={block.regularTitle}
          sectionLandmark={block.sectionLandmark}
        />
      );
    }
    default:
      return null;
  }
};

export default BodyRenderer;
