import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import HTag from 'components/common/HTag';
import PopularProductItem from 'components/common/PopularProductItem';
import ProductCarousel from 'components/common/ProductCarousel';
import { CarouselMasks } from 'components/common/ProductCarousel/models';

import './PopularProducts.scss';

type TPopularProductsComponentProps = {
  products: ProductTypes.IProduct[];
  boldTitle: string;
  regularTitle: string;
  sectionLandmark: string;
};
const PopularProducts: FC<TPopularProductsComponentProps> = ({
  products,
  boldTitle,
  regularTitle,
  sectionLandmark,
}) => {
  return (
    <section className="dt-popular-products dt-container-wrapper" aria-label={sectionLandmark}>
      <Container fluid>
        <HTag underline size={2} regularText={regularTitle} boldText={boldTitle} />
      </Container>
      <ProductCarousel
        partialVissible
        infinite={false}
        type={CarouselMasks.bothMasks}
        limitWidth={products.length < 4}
        isBtnGroup={products.length > 3}
      >
        {products?.map((product) => (
          <PopularProductItem key={product.id} product={product} />
        ))}
      </ProductCarousel>
    </section>
  );
};

export default PopularProducts;
